<template>
  <div>
    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
  </div>
</template>
<script>
import { HTTP } from '@/services/http-common'
import { constants } from '@/shared/constants'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import AutoformatPlugin from '@ckeditor/ckeditor5-autoformat/src/autoformat'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import BlockQuotePlugin from '@ckeditor/ckeditor5-block-quote/src/blockquote'
// import CKFinderPlugin from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
// import CKFinderUploadAdapter from '@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter'
// import EasyImagePlugin from '@ckeditor/ckeditor5-easy-image/src/easyimage' // requires CloudServices
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
// bof image
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import ImageResizePlugin from '@ckeditor/ckeditor5-image/src/imageresize'
import LinkImagePlugin from '@ckeditor/ckeditor5-link/src/linkimage'
// eof image
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ListPlugin from '@ckeditor/ckeditor5-list/src/list'
import MediaEmbedPlugin from '@ckeditor/ckeditor5-media-embed/src/mediaembed'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import PasteFromOfficePlugin from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import TablePlugin from '@ckeditor/ckeditor5-table/src/table'
import TableToolbarPlugin from '@ckeditor/ckeditor5-table/src/tabletoolbar'
// // not present in classic default
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment'
import PageBreakPlugin from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline'
import FontPlugin from '@ckeditor/ckeditor5-font/src/font'

const MEDIA_URL = constants.MEDIA_URL
var subDirectory = '',
thisUser = null,
thisBusiness = null

class BusinessUploadAdapter {
  constructor ( loader ) {
    this.loader = loader
  }
  upload () {
    return this.loader.file.then(file => {
      let formData  = new FormData()
      formData.append('slug', subDirectory)
      formData.append('file', file)
      formData.append('user', thisUser)
      formData.append('business', thisBusiness)
      return HTTP.post('media_objects', formData).then(resp => {
        resp.default = MEDIA_URL + resp.data.contentUrl
        return resp
      }, error => {
        console.log(error)
      })
    })
  }
  abort () {
    // Reject promise returned from upload() method.
  }
}

function BusinessUploadAdapterPlugin( editor ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      return new BusinessUploadAdapter( loader )
  }
}

export default {
  name: 'RichTextEditor',
  props: {
    value: {
      default () { return '' }
    },
    content: {
      default () { return '' }
    },
    tagName: {
      default () { return 'div' }
    },
    pageSlug: {
      default () { return '' }
    },
    placeholder: {
      default () { return '' }
    }
  },
  data () {
   return {
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        mediaEmbed: {
          previewsInData: true
        },
        placeholder: this.placeholder,
        plugins: [
          EssentialsPlugin,
          AutoformatPlugin,
          BoldPlugin,
          ItalicPlugin,
          BlockQuotePlugin,
          // CKFinderPlugin,
          // CKFinderUploadAdapter,
          // EasyImagePlugin, // requires CloudServices
          HeadingPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          ImageResizePlugin,
          LinkImagePlugin,
          LinkPlugin,
          ListPlugin,
          MediaEmbedPlugin,
          ParagraphPlugin,
          PasteFromOfficePlugin,
          TablePlugin,
          TableToolbarPlugin,
          // not present in classic default
          AlignmentPlugin,
          PageBreakPlugin,
          FontPlugin,
          HorizontalLine
        ],

        toolbar: {
	        viewportTopOffset: 30,
          items: [
            'heading',
            '|',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'alignment',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'horizontalLine',
            // 'pageBreak',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo'
          ],
          // autosave: {
          //   save() {
          //     this.savePage()
          //   }
          // }
        },
        extraPlugins: [ BusinessUploadAdapterPlugin ],
        image: {
          resizeOptions: [
            {
              name: 'resizeImage:original',
              value: '100',
              icon: 'original'
            },
            {
              name: 'resizeImage:100',
              value: null,
              icon: 'large'
            }
          ],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            // 'imageStyle:full', // @XXX removed?
            'imageStyle:alignRight',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'linkImage',
            '|',
            'resizeImage:100',
            'resizeImage:original'
          ],
          styles: [
            'full',
            'alignLeft',
            'alignCenter',
            'alignRight'
          ],
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        }
      }
    }
  },
  watch: {
    pageSlug (val) {
      subDirectory = this.currentBusiness.uriName + '/' + val
    },
    value (val) {
      this.editorData = val
    },
    content (val) {
      this.editorData = val
    },
    editorData (val) {
      this.$emit('input', val)
    }
  },
  created () {
    subDirectory = this.currentBusiness.uriName + '/' + this.pageSlug
    thisUser = this.currentUser.id
    thisBusiness = this.currentBusiness.id
  }
}
</script>